<template>
    <div>
      <branch-form
        :is-branch-form-sidebar-active.sync="isBranchFormSidebarActive"
  
        :branch-data="BranchData"
        @refetch-data="refetchData" 
      />
    
      <b-card no-body>
          <div class="m-2">
              <b-row>
                  <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
  
                      
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-button
                        variant="primary"
                        @click="addBranch"
                      
                      >
                        <span class="text-nowrap">Add Branch</span>
                      </b-button>
                    </div>
                  </b-col>
              </b-row>
          </div>
          <b-table
              ref="refBranchListTable"
              :items="fetchData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
          >
              <template #head(action)>
                  <feather-icon
                  icon="TrendingUpIcon"
                  class="mx-auto"
                  />
              </template>
              <template #cell(action)="data">
                  
                  
  
                   <b-button
                   class="ml-1"
              
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      @click="updateBranch(data.item)"
  
                  >
                
                      
                      Edit
                  </b-button>
                  <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="ml-1"
           
                      variant="outline-danger" @click="deleteconfirm(data.item.id)">Delete</b-button>
              </template>
          </b-table>
          <div class="mx-2 mb-2">
              <b-row>
  
                  <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
  
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="totalBranchs"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                      <template #prev-text>
                      <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                      />
                      </template>
                      <template #next-text>
                      <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                      />
                      </template>
                  </b-pagination>
  
                  </b-col>
  
              </b-row>
          </div>
        
      </b-card>
    </div>
  </template>
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  //api
  import axios from '@axios'
  import store from '@/store'
  

  import { ref,onUnmounted } from '@vue/composition-api'


  import BranchForm from '../branch-form/BranchForm.vue'
  import useBranchList from './useBranchList'
  import branchStoreModule from '../branchStoreModule'
  //import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  
  export default{
      components: {
          BCard,
          BRow,
          BCol,
          BFormInput,
          BButton,
          BTable,
          BMedia,
          BAvatar,
          BLink,
          BBadge,
          BDropdown,
          BDropdownItem,
          BPagination,
          BTooltip,
          VBModal,
          BFormGroup,
          BFormCheckbox,
          vSelect,
          BranchForm
      },
      setup() {
        const isBranchFormSidebarActive = ref(false)
        const BranchData = ref({})
          const BRANCH_STORE_MODULE_NAME = 'users-branch'
          if (!store.hasModule(BRANCH_STORE_MODULE_NAME)) store.registerModule(BRANCH_STORE_MODULE_NAME, branchStoreModule)
  
              // UnRegister on leave
              onUnmounted(() => {
              if (store.hasModule(BRANCH_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_STORE_MODULE_NAME)
              });
              const updateBranch= (data)=>{
  
                BranchData.value = data;
                isBranchFormSidebarActive.value = true;
              }
              const addBranch = (data)=>{
  
                  BranchData.value = {};
                  isBranchFormSidebarActive.value = true;
            }
  
      const permissionsSettingData = [
      {
          module: 'Settings',
          general: false,
          permission: false
         
        },
      ];
    
          const {
              fetchData,
              tableColumns,
              perPage,
              currentPage,
              totalBranchs,
              dataMeta,
              perPageOptions,
              searchQuery,
              sortBy,
              isSortDirDesc,
              refBranchListTable,
          
              statusFilter,
              
            
          
              refetchData,
              deleteData
          } = useBranchList();
          return {
              fetchData,
              tableColumns,
              perPage,
              currentPage,
              totalBranchs,
              dataMeta,
              perPageOptions,
              searchQuery,
              sortBy,
              isSortDirDesc,
              refBranchListTable,
          
              statusFilter,
          
            
              
              refetchData,
             
              isBranchFormSidebarActive,
              BranchData,
              addBranch,
              updateBranch,
              deleteData
          }
          //if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
      },
      directives:{
          'b-modal': VBModal,
          Ripple
  
      },
      data: () => ({
          name: '',
          modalShow:false,
          nameState: null,
          submittedNames: [],
          
      }),
      methods: {
     
     
      deleteconfirm(id){
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete Branch ID.'+id, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value === true){
             this.deleteData(id)
            }
         
            //this.deleteData(id)
          })
  
      },
      makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
          title: title,
          variant,
          solid: true,
        })
      },
      
    },
  }
  </script>
  