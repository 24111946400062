import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useBranchList() {
    
    const toast = useToast()
    const refBranchListTable = ref(null);
    const tableColumns = [
      
        { key: 'title', label: 'Branch', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ]
    const perPage = ref(10)
    const totalBranchs = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refBranchListTable.value ? refBranchListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalBranchs.value,
        }
    })
    const refetchData = () => {
        refBranchListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })
    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
        console.log('offset',offset);
        store
          .dispatch('users-branch/fetchData', {
            limit: perPage.value,
            offset:offset
           /* q: searchQuery.value,
            
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  branchs  = response.data.data
         
            const total = response.data.total;
            callback(branchs)
            totalBranchs.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching invoices' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const deleteData = (id) => {
      //fetchDeleteProperty
      store.dispatch('users-branch/fetchDeleteData',id).then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete Branch",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
      //console.log('id', id);
      
    }
    return {
        fetchData,
        tableColumns,
        perPage,
        currentPage,
        totalBranchs,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refBranchListTable,
    
        statusFilter,
    
   
    
        refetchData,
        deleteData
     }
}